import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore, serverTimestamp } from "firebase/firestore"
import { getFunctions } from "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyDXYZrzL_ka8Ple8gUrYDG-_Cu8kkbwabk",
  authDomain: "frietekes-ad1be.firebaseapp.com",
  projectId: "frietekes-ad1be",
  storageBucket: "frietekes-ad1be.appspot.com",
  messagingSenderId: "929579517086",
  appId: "1:929579517086:web:2074c3afefb61d4a9e278f",
}

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
export const db = getFirestore(app)
export const functions = getFunctions(app, "europe-west1")
export const firestoreTimeStamp = serverTimestamp
