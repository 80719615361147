import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Typography,
  CircularProgress,
} from "@mui/material"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Check, DeleteOutline } from "@mui/icons-material"
import { removeCoupon, addCoupon } from "../../store/coupon"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../utils/firebase"
import { useSnackbar } from "notistack"

const CartFooterConfirm = () => {
  const [code, setCode] = useState("")
  const [isValidating, setIsValidating] = useState(false)

  const { totalPrice } = useSelector((state) => state.cart)
  const { coupon } = useSelector((state) => state.coupon)

  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  const handleCoupon = async () => {
    setIsValidating(true)
    const validateCoupon = httpsCallable(functions, "validateCoupon")

    try {
      const response = await validateCoupon({
        code: code,
        amnt: totalPrice,
      })

      const {
        data: { data, error },
      } = response

      if (error) {
        console.log(error)
        enqueueSnackbar(error, {
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
          variant: "error",
        })
      } else {
        dispatch(addCoupon(data))
        setCode("")
      }

      setIsValidating(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleRemoveCoupon = () => {
    dispatch(removeCoupon())
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          my: 4,
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "lighter" }}>
          COUPON
        </Typography>
        {coupon ? (
          <>
            <Typography sx={{ mr: 2, ml: "auto", alignSelf: "center" }}>
              {coupon.label.toUpperCase()}
            </Typography>
            <IconButton color="error" onClick={handleRemoveCoupon}>
              <DeleteOutline />
            </IconButton>
          </>
        ) : (
          <FormControl variant="standard" sx={{ flexBasis: "250px" }}>
            <InputLabel htmlFor="coupon-input">Code</InputLabel>
            <Input
              id="coupon-input"
              label="Coupon"
              variant="standard"
              value={code}
              onChange={(event) =>
                setCode(event.target.value.toLowerCase().trim())
              }
              endAdornment={
                isValidating ? (
                  <CircularProgress />
                ) : (
                  <IconButton onClick={handleCoupon}>
                    <Check color="primary" />
                  </IconButton>
                )
              }
            />
          </FormControl>
        )}
      </Box>
      {coupon && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "lighter" }}>
              SUBTOTAAL
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }} align="right">
              {(totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "lighter" }}>
              KORTING
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }} align="right">
              {(-coupon.amount / 100).toFixed(2).replace(".", ",")} &euro;
            </Typography>
          </Box>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "lighter" }}>
          TOTAAL
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }} align="right">
          {coupon
            ? ((totalPrice - coupon.amount) / 100).toFixed(2).replace(".", ",")
            : (totalPrice / 100).toFixed(2).replace(".", ",")}
          &euro;
        </Typography>
      </Box>
    </>
  )
}

export default CartFooterConfirm
