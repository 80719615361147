import { Typography } from "@mui/material"
import React from "react"

import PropTypes from "prop-types"

const FooterHeader = ({ label }) => {
  return (
    <Typography
      variant="h6"
      sx={{ fontWeight: "light", textTransform: "uppercase", mb: 2 }}
    >
      {label}
    </Typography>
  )
}

FooterHeader.propTypes = {
  label: PropTypes.string.isRequired,
}

export default FooterHeader
