import { Grid, Typography } from "@mui/material"
import React from "react"

const OpeningHoursGrid = () => {
  return (
    <Grid container sx={{ alignItems: "center" }}>
      <Grid item xs={6}>
        <Typography variant="body3">Ma, Di, Woe</Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography sx={{ fontWeight: "medium" }}>Gesloten</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body3">Donderdag</Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography sx={{ fontWeight: "medium" }}>17h - 22h</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body3">Vrijdag</Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography sx={{ fontWeight: "medium" }}>17h - 22h</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body3">Zaterdag</Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography sx={{ fontWeight: "medium" }}>17h - 22h</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body3">Zondag</Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography sx={{ fontWeight: "medium" }}>17h - 21h</Typography>
      </Grid>
    </Grid>
  )
}

export default OpeningHoursGrid
