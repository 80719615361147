import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import FooterHeader from "./FooterHeader";
import OpeningHoursGrid from "./OpeningHoursGrid";

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        p: 4,
        display: "flex",
        flexDirection: "column",
        borderTop: "solid white 2px",
        gap: 2,
      }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={8}>
          <FooterHeader label="Contact" />
          <div>
            <Typography sx={{ fontSize: "h5.fontSize" }}>
              <span style={{ fontWeight: "700" }}>FRIET</span>
              <span style={{ fontWeight: "200" }}>EKES</span>
            </Typography>
            <Typography variant="body3">Gooikenstraat 54A</Typography>
            <Typography variant="body3">9308 Aalst</Typography>
            <Link color="inherit" href="tel:+3253397883">
              053 39 78 83
            </Link>
            <br />
            <Link color="inherit" href="mailto:info@frietekes.be">
              info@frietekes.be
            </Link>
            <br />
            <Typography variant="body3">BE 0718.943.412</Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FooterHeader label="Openingsuren" />
          <OpeningHoursGrid />
        </Grid>
      </Grid>

      <span style={{ display: "flex", justifyContent: "space-around" }}>
        <Link color="inherit" href="/termsofuse">
          Algemene voorwaarden
        </Link>
        {/* <Link color="inherit" href="">
          Privacy
        </Link> */}
      </span>

      <Typography
        sx={{ fontWeight: "light", fontSize: "small", alignSelf: "center" }}>
        &copy; 2024 - BreckxIT
      </Typography>
    </Box>
  );
};

export default Footer;
