import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  selectedZone: null,
  isDelivery: true,
}

const sessionSlice = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    selectZone: (state, action) => {
      state.selectedZone = action.payload
    },
    deselectZone: (state) => {
      state.selectedZone = null
    },
    setDelivery: (state, action) => {
      if (!action.payload) {
        state.selectedZone = null
      }

      state.isDelivery = action.payload
    },
  },
})

export const { actions, reducer } = sessionSlice
export const { selectZone, deselectZone, setDelivery } = actions
