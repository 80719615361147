import { Box, IconButton, Stack, Typography } from "@mui/material"
import React, { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"

import DeleteIcon from "@mui/icons-material/DeleteOutline"
import { removeProduct } from "../../store/cart"

import PropTypes from "prop-types"

const CartContent = ({ isRemovable = true }) => {
  const { items } = useSelector((state) => state.cart)
  const dispatch = useDispatch()

  return (
    <Stack gap={4} sx={{ py: 2 }}>
      {items.map((it) => (
        <Fragment key={it.id}>
          <Box
            elevation={3}
            key={it.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {it.qty}x
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", textAlign: "right" }}
                >
                  {it.name}
                </Typography>
                {it.size !== null && (
                  <Typography align="right" sx={{ fontWeight: "light" }}>
                    {it.size}
                  </Typography>
                )}
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "300", textAlign: "right" }}
                >
                  {it.extras.map((ex) => ex.name).join(", ")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    flexShrink: 0,
                    textAlign: "right",
                  }}
                >
                  {((it.price * it.qty) / 100).toFixed(2).replace(".", ",")}{" "}
                  &euro;
                </Typography>
              </Box>
              {isRemovable && (
                <IconButton
                  color="error"
                  onClick={() => dispatch(removeProduct(it.id))}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Fragment>
      ))}
    </Stack>
  )
}

CartContent.propTypes = {
  isRemovable: PropTypes.bool.isRequired,
}

export default CartContent
