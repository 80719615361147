import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CartContent from "./CartContent";

import PropTypes from "prop-types";

const CartDialog = ({ open, setOpen }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { totalPrice, totalItems } = useSelector((state) => state.cart);
  const { selectedZone, isDelivery } = useSelector((state) => state.session);
  const { data } = useSelector((state) => state.zones);

  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isDelivery && selectedZone === null) {
      navigate("/");
    }
  }, [isDelivery, selectedZone, navigate]);

  const foundZone = data.find((z) => z.id === selectedZone?.id);

  const isEnoughToOrder =
    !isDelivery ||
    (foundZone !== undefined && foundZone.minAmount < totalPrice);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      sx={{ userSelect: "none" }}>
      <DialogTitle sx={{ textTransform: "uppercase", fontWeight: "300" }}>
        Winkelmand
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
        {totalItems > 0 ? (
          <CartContent isRemovable={true} />
        ) : (
          <Typography variant="h6" align="center">
            Geen producten
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}>
        <Box sx={{ textAlign: "right", mt: 4, mb: 4 }}>
          <Box>
            <Typography variant="overline">Totaal</Typography>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {(totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
            </Typography>
          </Box>

          {!isEnoughToOrder && (
            <Typography variant="body2" textAlign="right">
              Minimum bestelbedrag is{" "}
              {(foundZone?.minAmount / 100).toFixed(2).replace(".", ",")} &euro;
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}>
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            onClick={handleClose}>
            Sluit
          </Button>
          <Button
            size="large"
            disabled={!isEnoughToOrder}
            variant="contained"
            color="primary"
            sx={{
              color: "white",
            }}
            endIcon={<ChevronRightIcon />}
            onClick={() => {
              if (isEnoughToOrder) {
                navigate("/confirm");
                setOpen(false);
              }
            }}>
            Bestel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CartDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default CartDialog;
