import { httpsCallable } from "firebase/functions"
import React, { createContext, useContext, useEffect, useState } from "react"
import { functions } from "../utils/firebase"

const OpenContext = createContext({ isOpen: false })

// eslint-disable-next-line react/prop-types
export const OpenContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState()

  useEffect(() => {
    const fetchIsOpen = async () => {
      const getIsOpen = httpsCallable(functions, "getIsOpen")
      const result = await getIsOpen()

      const {
        data: { payload },
      } = result

      setIsOpen(payload.isOpen)
    }
    fetchIsOpen()
  }, [])

  return (
    <OpenContext.Provider
      value={{
        isOpen: isOpen,
      }}
    >
      {children}
    </OpenContext.Provider>
  )
}

export const useIsOpen = () => useContext(OpenContext)
