import React, { useEffect } from "react";

import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HomeScreen from "./screens/HomeScreen";
import CatalogScreen from "./screens/CatalogScreen";
import { Routes, Route } from "react-router-dom";
import ConfirmScreen from "./screens/ConfirmScreen";
import ConfirmationScreen from "./screens/ConfirmationScreen";
import { NotFoundScreen } from "./screens/NotFoundScreen";
import ScrollToTop from "./utils/scrollToTop";
import TermsScreen from "./screens/TermsScreen";
import { useDispatch } from "react-redux";
import { fetchZones } from "./store/zones";
import { useIsOpen } from "./contexts/IsOpenContext";
// import TombolaScreen from "./screens/TombolaScreen";

function App() {
  const dispatch = useDispatch();

  const { isOpen } = useIsOpen();

  useEffect(() => {
    dispatch(fetchZones());
  }, [dispatch]);

  return (
    <>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/catalog" element={<CatalogScreen isOpen={isOpen} />} />
        <Route path="/confirm" element={<ConfirmScreen />} />
        <Route path="/confirmation/:id" element={<ConfirmationScreen />} />
        <Route path="/termsofuse" element={<TermsScreen />} />
        {/* <Route path="/tombola" element={<TombolaScreen />} /> */}
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
