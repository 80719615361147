import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const NotFoundScreen = () => {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Typography>Niet gevonden</Typography>
      <Link to="/">Keer terug naar de homepagina.</Link>
    </Box>
  );
};
