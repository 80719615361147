import React from "react"

const GoogleMap = () => {
  return (
    <iframe
      title="Maps"
      src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJpzpBIPGXw0cRb2wv6kqhlXE&key=AIzaSyB39S8fQFli1gXhnD05HeHQVw2rOy8sV-E"
      style={{ border: 0, width: "100%", height: 400 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  )
}

export default GoogleMap
