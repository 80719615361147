import { Box, Divider, Typography } from "@mui/material"
import React from "react"
import PropTypes from "prop-types"

function DialogHeader({ label }) {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="overline" sx={{ fontWeight: "300" }}>
        {label}
      </Typography>
      <Divider />
    </Box>
  )
}

DialogHeader.propTypes = {
  label: PropTypes.string.isRequired,
}

export default DialogHeader
