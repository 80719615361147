import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import * as yup from "yup";

import { v4 as uuidv4 } from "uuid";

import { ChevronRight } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { doc, setDoc } from "firebase/firestore";
import { db, firestoreTimeStamp, functions } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { useSnackbar } from "notistack";

const takeOutValidationSchema = yup.object({
  firstName: yup.string().required("Voornaam is verplicht."),
  lastName: yup.string().required("Achternaam is verplicht."),
  telephone: yup.string().required("Telefoon is verplicht."),
  email: yup
    .string("Geef een emailadres in.")
    .email("Geef een geldig emailadres in.")
    .required("Emailadres is verplicht."),
});

const deliveryValidationSchema = yup.object({
  firstName: yup.string().required("Voornaam is verplicht."),
  lastName: yup.string().required("Achternaam is verplicht."),
  street: yup.string().required("Straat is verplicht."),
  number: yup.string().required("Huisnummer is verplicht."),
  postalCode: yup.string().min(4).max(4).required("Postcode is verplicht."),
  city: yup.string().required("Gemeente is verplicht."),
  telephone: yup.string().required("Telefoon is verplicht."),
  email: yup
    .string("Geef een emailadres in.")
    .email("Geef een geldig emailadres in.")
    .required("Emailadres is verplicht."),
});

const deliveryInitialState = {
  firstName: "",
  lastName: "",
  street: "",
  number: "",
  bus: "",
  telephone: "",
  email: "",
  comments: "",
  payment: "cash",
  freeProduct: "",
};

const takeOutInitialState = {
  firstName: "",
  lastName: "",
  telephone: "",
  email: "",
  comments: "",
  payment: "cash",
  freeProduct: "",
};

const Form = ({
  times = [],
  timesIsLoading,
  isOpen,
  freeProductsLoading,
  freeProducts,
  setTimes,
  setIsOpen,
}) => {
  const { items, totalPrice } = useSelector((state) => state.cart);
  const { isDelivery, selectedZone } = useSelector((state) => state.session);
  const { coupon } = useSelector((state) => state.coupon);

  const [isOrdering, setIsOrdering] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const initialTime = times.length ? times[0] : "";

  const navigate = useNavigate();

  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: isDelivery
        ? {
            ...deliveryInitialState,
            time: initialTime,
            postalCode: selectedZone.postalCode,
            city: selectedZone.name,
          }
        : { ...takeOutInitialState, time: initialTime },
      validationSchema: isDelivery
        ? deliveryValidationSchema
        : takeOutValidationSchema,
      onSubmit: async (data) => {
        setIsOrdering(true);

        let orderData = {
          ...data,
          totalPrice: coupon !== null ? totalPrice - coupon.amount : totalPrice,
          created: firestoreTimeStamp(),
          items,
          delivery: isDelivery,
          coupon:
            coupon !== null
              ? { label: coupon.label, amount: coupon.amount }
              : null,
          status: data.payment === "online" ? "pending" : "new",
        };

        const getTimes = httpsCallable(functions, "fetchTimes");

        const result = await getTimes({ delivery: isDelivery });
        const {
          data: {
            data: { times, open },
          },
        } = result;

        const newId = uuidv4();

        const isTimeInTimes = times.some((t) => t === data.time);

        // console.log(`Frituur is ${open ? "open" : "gesloten"}`);
        // console.log(
        //   `Tijdslot is ${
        //     isTimeInTimes ? "nog aanwezig" : "niet meer te selecteren"
        //   }`
        // );

        if (!open || !isTimeInTimes) {
          const message = !open
            ? "Frituur is momenteel gesloten!"
            : "Het gekozen tijdslot is niet meer beschikbaar, kies een nieuw tijdslot!";

          enqueueSnackbar(message, {
            autoHideDuration: 5000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
            variant: "error",
            // TODO: Find the correct event listener to setTimes en setOpen afterwards
            // setTimes(times);
            // setIsOpen(open);
            onEnter: () => {
              setTimes(times);
              setIsOpen(open);
            },
          });

          return;
        }

        try {
          // Set the order in the DB
          await setDoc(doc(db, "orders", newId), orderData);

          if (data.payment === "online") {
            const getPaymentUrl = httpsCallable(functions, "newPayment");

            const result = await getPaymentUrl({
              amnt:
                coupon !== null
                  ? `${((totalPrice - coupon.amount) / 100).toFixed(2)}`
                  : `${(totalPrice / 100).toFixed(2)}`,
              orderId: newId,
            });

            const {
              payload: { url },
              status,
            } = result.data;

            if (status === "error") {
              enqueueSnackbar("Er is iets fout gegaan", {
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "center" },
                variant: "error",
              });
            }
            // Navigate to the mollie payment url
            window.location.replace(url);
          } else {
            return navigate(`/confirmation/${newId}`, {
              replace: true,
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsOrdering(false);
        }
      },
    });

  // TODO: Place formik in parent component to set the field value if fetched data
  // You may not need this useEffect
  useEffect(() => {
    if (times.length) {
      setFieldValue("time", times[0]);
    }
    if (freeProducts.length) {
      if (freeProducts[0]) {
        setFieldValue("freeProduct", freeProducts[0]?.name);
      }
    }
  }, [setFieldValue, times, freeProducts]);

  return (
    <Box component="form" onSubmit={handleSubmit} autoComplete="off" noValidate>
      <Grid container spacing={2} sx={{ my: 4 }}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            id="firstName"
            name="firstName"
            label="Voornaam"
            value={values.firstName}
            onChange={handleChange}
            error={touched.firstName && Boolean(errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            id="lastName"
            name="lastName"
            label="Achternaam"
            value={values.lastName}
            onChange={handleChange}
            error={touched.lastName && Boolean(errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
        </Grid>
        {isDelivery && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                id="street"
                name="street"
                label="Straat"
                value={values.street}
                onChange={handleChange}
                error={touched.street && Boolean(errors.street)}
                helperText={touched.street && errors.street}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                required
                id="number"
                name="number"
                label="Huisnummer"
                value={values.number}
                onChange={handleChange}
                error={touched.number && Boolean(errors.number)}
                helperText={touched.number && errors.number}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                id="bus"
                name="bus"
                label="Bus"
                value={values.bus}
                onChange={handleChange}
                error={touched.bus && Boolean(errors.bus)}
                helperText={touched.bus && errors.bus}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                fullWidth
                required
                id="postalCode"
                name="postalCode"
                label="Postcode"
                value={values.postalCode}
                onChange={handleChange}
                error={touched.postalCode && Boolean(errors.postalCode)}
                helperText={touched.postalCode && errors.postalCode}
                disabled
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                fullWidth
                required
                id="city"
                name="city"
                label="Gemeente"
                value={values.city}
                onChange={handleChange}
                disabled
                InputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            id="email"
            name="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </Grid>{" "}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            id="telephone"
            name="telephone"
            label="Telefoon/GSM"
            value={values.telephone}
            onChange={handleChange}
            error={touched.telephone && Boolean(errors.telephone)}
            helperText={touched.telephone && errors.telephone}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            multiline
            minRows={4}
            id="comments"
            name="comments"
            label="Opmerkingen"
            value={values.comments}
            onChange={handleChange}
            error={touched.comments && Boolean(errors.comments)}
            helperText={touched.comments && errors.comments}
          />
        </Grid>
        <Divider />
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={touched.time && Boolean(errors.time)}>
            <InputLabel id="time-select-label">Tijd</InputLabel>
            <Select
              id="time-select"
              labelId="time-select-label"
              label="Tijd"
              name="time"
              value={values.time}
              onChange={handleChange}>
              {times.map((t) => (
                <MenuItem key={t} value={t}>
                  {t === "asap" ? "Zo snel mogelijk" : t}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {isDelivery && (
          <Grid item xs={12} md={12}>
            <FormControl
              fullWidth
              error={touched.freeProduct && Boolean(errors.freeProduct)}>
              <InputLabel id="free-product">Gratis product</InputLabel>
              <Select
                id="free-product"
                labelId="free-product"
                label="Gratis product"
                name="freeProduct"
                value={values.freeProduct}
                onChange={handleChange}>
                {freeProducts.map((p) => (
                  <MenuItem key={p.id} value={p.name}>
                    {`Gratis ${p.name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <FormControl>
            <FormLabel id="payment-radio">Betaling</FormLabel>
            <RadioGroup
              aria-labelledby="payment-radio"
              name="payment"
              value={values.payment}
              onChange={(event) => {
                setFieldValue("payment", event.currentTarget.value);
              }}>
              <FormControlLabel value="cash" control={<Radio />} label="Cash" />
              <FormControlLabel
                value="online"
                control={<Radio />}
                label="Online"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
        }}>
        <Button
          sx={{ minWidth: 150 }}
          type="reset"
          size="large"
          color="secondary"
          variant="outlined">
          Annuleer
        </Button>
        <Button
          sx={{ minWidth: 150 }}
          type="submit"
          size="large"
          variant="contained"
          disabled={
            freeProductsLoading ||
            timesIsLoading ||
            !isOpen ||
            !times.length ||
            isOrdering
          }
          endIcon={isOpen && !timesIsLoading ? <ChevronRight /> : null}>
          {timesIsLoading || freeProductsLoading || isOrdering ? (
            <CircularProgress size={24} />
          ) : isOpen && times.length ? (
            "Bestel"
          ) : (
            "Gesloten"
          )}
        </Button>
      </Box>
    </Box>
  );
};

Form.propTypes = {
  times: PropTypes.array.isRequired,
  timesIsLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  freeProductsLoading: PropTypes.bool.isRequired,
  freeProducts: PropTypes.array.isRequired,
  setTimes: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default Form;
