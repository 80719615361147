export const selectListStyle = {
  control: (provided, state) => ({
    ...provided,
    height: 45,
    minHeight: 45,
    borderColor: state.isFocused ? "#F9B11C" : "#ced4da",
    "&:hover": { borderColor: "#F9B11C" },
    boxShadow: "none",
  }),
  menu: (provided) => ({
    ...provided,
    color: "black",
    padding: 10,
    zIndex: 99999,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 99999,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled
      ? undefined
      : state.isSelected
      ? "#F9B11C"
      : state.isFocused
      ? "#ffc954"
      : undefined,
    ":active": {
      ...provided[":active"],
      backgroundColor: !state.isDisabled
        ? state.isSelected
          ? "#F9B11C"
          : "#ffc954"
        : undefined,
    },
  }),
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: "#ffc954",
    }
  },
}
