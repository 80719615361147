import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectZone } from "../store/session"
import { useNavigate } from "react-router-dom"

const ZoneDialog = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data } = useSelector((state) => state.zones)
  const { selectedZone } = useSelector((state) => state.session)

  const handleNavigate = () => {
    if (selectedZone) {
      setOpen(false)
      navigate("/catalog")
    }
  }

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      maxWidth="md"
      fullWidth
      sx={{ userSelect: "none" }}
    >
      <DialogTitle>Kies uw gemeente</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          name="zone"
          onChange={(e) => {
            const selectedZon = data.find((z) => z.id === e.target.value)
            if (selectedZon) {
              dispatch(selectZone(selectedZon))
            }
          }}
        >
          {data.length > 0 &&
            data.map((zone) => (
              <FormControlLabel
                sx={{ mb: 2 }}
                value={zone.id}
                key={zone.id}
                control={
                  <Radio
                    color="primary"
                    checked={selectedZone && selectedZone.id === zone.id}
                  />
                }
                label={
                  <>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {zone.name}
                    </Typography>
                    <Typography sx={{ fontWeight: "light" }}>
                      v.a. {(zone.minAmount / 100).toFixed(2).replace(".", ",")}
                      &euro;
                    </Typography>
                  </>
                }
              />
            ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          Annuleer
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleNavigate}
        >
          Bevestigen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ZoneDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default ZoneDialog
