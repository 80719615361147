import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DialogHeader from "../components/DialogHeader";
import { clearCart } from "../store/cart";
import { removeCoupon } from "../store/coupon";
import { db } from "../utils/firebase";
import { NotFoundScreen } from "./NotFoundScreen";

const ConfirmationScreen = () => {
  const { id } = useParams();

  // local state
  const [order, setOrder] = useState(null);
  const [isOrderLoading, setIsOrderLoading] = useState(false);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setIsOrderLoading(true);
    const unsubscribe = onSnapshot(doc(db, "orders", id), (doc) => {
      setOrder(doc.data());
      setIsOrderLoading(false);
    });

    return unsubscribe;
  }, [id]);

  useEffect(() => {
    if (order) {
      if (order.status === "new" || order.status === "confirmed") {
        dispatch(removeCoupon());
        dispatch(clearCart());
      } else if (order.status === "canceled") {
        enqueueSnackbar(
          "Online betaling niet gelukt. Probeer opnieuw of kies een andere betaalmethode.",
          {
            anchorOrigin: { vertical: "top", horizontal: "center" },
            autoHideDuration: 10000,
            variant: "error",
          }
        );
        navigate("/confirm", { replace: true });
      }
    }
  }, [dispatch, enqueueSnackbar, navigate, order]);

  if (isOrderLoading || order?.status === "pending") {
    return (
      <Box
        sx={{
          flex: 1,
          p: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <>
          {order?.status === "pending" && (
            <Typography variant="h5" sx={{ fontWeight: "light", mb: 4 }}>
              Online betaling verwerken...
            </Typography>
          )}
          <CircularProgress size={40} disableShrink />
        </>
      </Box>
    );
  }

  if (!order && !isOrderLoading) {
    return <NotFoundScreen />;
  }

  return (
    <Box sx={{ flex: 1, p: 4 }}>
      <Typography align="center" variant="h4" sx={{ mb: 4 }}>
        Bedankt voor uw bestelling
      </Typography>
      <Typography align="center" sx={{ mb: 4 }}>
        Merkt u een fout op in uw bestelling of in uw gegevens? <br></br>Gelieve
        dan zo snel mogelijk contact op te nemen met ons op het nummer{" "}
        <a style={{ color: "inherit" }} href="tel:053397883">
          053 39 78 83
        </a>
        <br></br>
        Team Frietekes.
      </Typography>

      <DialogHeader label="Gegevens" />
      <Typography>{`${order.firstName} ${order.lastName}`}</Typography>
      {order.delivery && (
        <>
          <Typography>{`${order.street} ${order.number} ${order.bus}`}</Typography>
          <Typography
            sx={{ mb: 4 }}>{`${order.postalCode} ${order.city}`}</Typography>
        </>
      )}

      <DialogHeader label="Winkelmandje" />
      {order.items.map((it) => (
        <Box
          sx={{
            p: 2,
          }}
          key={it.id}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <Typography sx={{ fontWeight: "bold" }}>{it.qty}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>{it.name}</Typography>
            <Typography
              sx={{ fontWeight: "light" }}
              className="fw-lighter fs-6">
              {it?.size}
            </Typography>
          </Box>
          <Typography sx={{ ml: 6, fontWeight: "lighter" }}>
            {it.extras.map((e) => e.name).join(", ")}
          </Typography>
        </Box>
      ))}
      {order.freeProduct && (
        <Box sx={{ display: "flex", alignItems: "center", gap: 3, p: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>1</Typography>
          <Typography sx={{ fontWeight: "bold" }}>
            Gratis {order.freeProduct}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 4,
          p: 2,
        }}>
        <Typography sx={{ fontWeight: "lighter", textTransform: "uppercase" }}>
          Totaal
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: "bold" }} align="right">
          {(order.totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
        </Typography>
      </Box>
      {order.coupon !== null && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: 2,
          }}>
          <Typography variant="h6" sx={{ fontWeight: "light" }} align="right">
            {`U heeft een coupon gebruikt van ${(order.coupon.amount / 100)
              .toFixed(2)
              .replace(".", ",")}`}
            &euro;
          </Typography>
        </Box>
      )}
      <Grid container>
        <Grid item lg={6}>
          <DialogHeader label={order.delivery ? "Levering" : "Afhaling"} />
          <Box
            className="d-flex align-items-center justify-content-end"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}>
            <Typography className="fw-light">U heeft gekozen voor </Typography>
            <Typography variant="h6" sx={{ ml: 1, fontWeight: "bold" }}>
              {order.delivery ? " levering " : " afhaling "}
            </Typography>
            <Typography className="fw-light ms-1" sx={{ ml: 1 }}>
              en dit
            </Typography>

            <Typography variant="h6" sx={{ ml: 1, fontWeight: "bold" }}>
              {order.time === "asap"
                ? "zo snel mogelijk *"
                : `rond ${order.time} *`}
            </Typography>
          </Box>
          {!order.delivery && (
            <Box
              className="d-flex align-items-center justify-content-end"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}>
              <p className="fw-light">te </p>
              <Typography variant="h6" sx={{ ml: 1, fontWeight: "bold" }}>
                Gooikenstraat 54A, 9308 Aalst
              </Typography>
            </Box>
          )}
          <Typography
            className="fw-lighter text-end"
            sx={{ fontWeight: "light" }}
            align="right">
            * Wij doen ons uiterste best om uw bestelling zo snel mogelijk te
            bezorgen/klaar te maken, bezorgtijden/afhaaltijden kunnen afwijken
            naargelang de drukte.
          </Typography>
        </Grid>
        <Grid item lg={6}>
          <DialogHeader label="Betaling" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}>
            {order?.payment === "online" ? (
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                U heeft online betaald.
              </Typography>
            ) : (
              <>
                <Typography sx={{ fontWeight: "light" }}>
                  U dient nog
                </Typography>
                <Typography variant="h6" sx={{ ml: 1, fontWeight: "bold" }}>
                  {(order.totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
                </Typography>
                <Typography sx={{ ml: 1, fontWeight: "light" }}>
                  te betalen.
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmationScreen;
