import { createSlice } from "@reduxjs/toolkit"

const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    coupon: null,
  },
  reducers: {
    addCoupon: (state, action) => {
      state.coupon = action.payload
    },
    removeCoupon: (state) => {
      state.coupon = null
    },
  },
})

export const { actions, reducer } = couponSlice
export const { addCoupon, removeCoupon } = actions
