import { Box, Typography } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"

import PropTypes from "prop-types"

const CartFooter = ({ openCart, isOpen = true }) => {
  const { totalItems, totalPrice } = useSelector((state) => state.cart)

  return (
    <Box
      onClick={() => {
        totalItems > 0 && isOpen && openCart(true)
      }}
      sx={{
        position: "sticky",
        bgcolor: "primary.main",
        bottom: 0,
        display: "flex",
        justifyContent: totalItems === 0 ? "center" : "space-between",
        py: 3,
        px: 8,
        borderTop: 16,
        borderColor: "white",
        cursor: isOpen && totalItems > 0 ? "pointer" : "not-allowed",
        userSelect: "none",
      }}
    >
      {isOpen ? (
        totalItems > 0 ? (
          <>
            <Box display="flex">
              <Typography variant="h5" sx={{ mr: 1, fontWeight: "bold" }}>
                {totalItems}
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "300" }}>
                {totalItems === 1 ? "product" : "producten"}
              </Typography>
            </Box>

            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {(totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
            </Typography>
          </>
        ) : (
          <Typography variant="h6" align="center" sx={{ width: "100%" }}>
            Geen producten
          </Typography>
        )
      ) : (
        <Typography variant="h6" align="center" sx={{ width: "100%" }}>
          Gesloten
        </Typography>
      )}
    </Box>
  )
}

CartFooter.propTypes = {
  openCart: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default CartFooter
