import { Box, Button, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { ChevronLeft } from "@mui/icons-material";
import CartContent from "../components/cart/CartContent";
import FooterHeader from "../components/FooterHeader";
import Form from "../components/Form";

import { httpsCallable } from "firebase/functions";
import { db, functions } from "../utils/firebase";
import CartFooterConfirm from "../components/cart/CartFooterConfirm";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

const ConfirmScreen = () => {
  const navigate = useNavigate();
  const [times, setTimes] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [timesIsLoading, setTimesIsLoading] = useState(false);
  const [freeProductsIsLoading, setFreeProductsIsLoading] = useState(false);
  const [freeProducts, setFreeProducts] = useState([]);

  const { totalItems } = useSelector((state) => state.cart);
  const { isDelivery } = useSelector((state) => state.session);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isDelivery) {
      setFreeProductsIsLoading(true);
      (async () => {
        const q = query(
          collection(db, "products"),
          where("relatedTags", "array-contains", "freeproduct"),
          where("isEnabled", "==", true),
          orderBy("order")
        );

        const qs = await getDocs(q);

        setFreeProducts(qs.docs.map((ds) => ({ id: ds.id, ...ds.data() })));
        setFreeProductsIsLoading(false);
      })();
    }
  }, [isDelivery]);

  useEffect(() => {
    setTimesIsLoading(true);
    const getTimesAsync = async () => {
      const getTimes = httpsCallable(functions, "fetchTimes");
      try {
        const result = await getTimes({ delivery: isDelivery });
        const {
          data: {
            data: { times, open },
          },
        } = result;

        // console.log(result);

        setTimes(times);
        setIsOpen(open);
        setTimesIsLoading(false);
      } catch (error) {
        enqueueSnackbar(`Er is iets fout gegaan: ${error.message}`, {
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "error",
        });
      }
    };
    getTimesAsync();
  }, [enqueueSnackbar, isDelivery]);

  if (totalItems < 1) {
    return <Navigate to="/catalog" replace />;
  }

  return (
    <Box sx={{ flex: 1, p: 4 }}>
      <Button
        variant="outlined"
        startIcon={<ChevronLeft />}
        sx={{
          mb: 4,
        }}
        onClick={() => navigate("/catalog")}>
        Verder winkelen
      </Button>
      <FooterHeader label="Winkelmandje" />
      <Box
        sx={{
          height: "300px",
          overflow: "scroll",
          borderBottom: "1px solid #F9B11C",
        }}>
        <CartContent isRemovable={false} />
      </Box>
      <CartFooterConfirm />
      <Divider />
      <FooterHeader label="Gegevens" />
      <Form
        times={times}
        timesIsLoading={timesIsLoading}
        isOpen={isOpen}
        freeProductsLoading={freeProductsIsLoading}
        freeProducts={freeProducts}
        setTimes={setTimes}
        setIsOpen={setIsOpen}
      />
    </Box>
  );
};

export default ConfirmScreen;
