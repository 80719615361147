import React, { useState, forwardRef, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Tab,
  Tabs,
  tabsClasses,
  Typography,
} from "@mui/material";

import PlusIcon from "@mui/icons-material/Add";
import { amber, grey } from "@mui/material/colors";
import { Link, Element } from "react-scroll";
import CartFooter from "../components/cart/CartFooter";
import ExtrasDialog from "../components/ExtrasDialog";
import CartDialog from "../components/cart/CartDialog";

import PropTypes from "prop-types";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../utils/firebase";

const SLink = forwardRef((props) => <Link {...props} />);
SLink.displayName = "SLink";

const LinkTab = (props) => {
  return (
    <Tab
      component={SLink}
      onClick={(event) => event.preventDefault()}
      {...props}
    />
  );
};

const CatalogScreen = ({ isOpen = true }) => {
  // Hold the selected index of the tabs component
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);
  // Hold the selected product
  const [extrasDialogOpen, setExtrasDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cartDialogOpen, setCartDialogOpen] = useState(false);

  const [catalog, setCatalog] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  // Categories is based on fetched products so outside of useEffect
  // While rerender the categories will be different
  useEffect(() => {
    setIsDataLoading(true);
    const q = query(
      collection(db, "products"),
      where("inCatalog", "==", true),
      orderBy("categoryOrder"),
      orderBy("order")
    );

    const unsubscribe = onSnapshot(q, (qs) => {
      const data = qs.docs.map((doc) => doc.data());
      setCatalog(data);
      setCategories([...new Set(data.map((c) => c.category))]);
      setIsDataLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (isDataLoading) {
    return (
      <Box
        sx={{
          flex: 1,
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <CircularProgress size={80} disableShrink />
      </Box>
    );
  }

  return (
    <Box sx={{ flex: 1 }}>
      {/* TODO: Extract Product component from catalog */}
      <CartDialog open={cartDialogOpen} setOpen={setCartDialogOpen} />
      <ExtrasDialog
        key={selectedProduct?.id}
        product={selectedProduct}
        open={extrasDialogOpen}
        setOpen={setExtrasDialogOpen}
      />
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          position: "sticky",
          overflow: "visible",
          top: 0,
          py: 3,
          zIndex: 100,
          mx: "auto",
          mt: 3,
        }}>
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          indicatorColor="primary"
          value={selectedTabIdx}
          onChange={(_, newIdx) => {
            setSelectedTabIdx(newIdx);
          }}
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}>
          {categories.map((c, idx) => (
            <LinkTab
              key={c}
              to={c}
              spy={true}
              duration={300}
              offset={-120}
              hashSpy={true}
              ignoreCancelEvents={true}
              onSetActive={() => setSelectedTabIdx(idx)}
              label={c}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ flex: 1, p: 4 }}>
        {categories.map((c) => (
          <Element
            key={c}
            id={c}
            className="element"
            style={{ marginBottom: 32 }}>
            <Typography
              sx={{
                fontWeight: "light",
                textTransform: "uppercase",
                fontSize: "h6.fontSize",
                marginBottom: 4,
              }}>
              {c}
            </Typography>
            {catalog
              .filter((p) => p.category === c)
              .map((p) => (
                <div
                  key={p.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 16,
                    minHeight: 75,
                    borderBottom: "solid 1px #eee",
                  }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}>
                      <Typography
                        sx={{ fontWeight: "medium", fontSize: "h6.fontSize" }}>
                        {p.name}
                      </Typography>
                      {/* Spicy tag */}
                      <Typography>
                        {p.tags && p.tags.length && p.tags.includes("spicy")
                          ? "🌶️"
                          : null}
                        {p.tags && p.tags.length && p.tags.includes("cheese")
                          ? "🧀"
                          : null}
                        {p.tags && p.tags.length && p.tags.includes("vegan")
                          ? "🥬"
                          : null}
                      </Typography>
                      {p.isNew && (
                        <Chip
                          label="NEW"
                          sx={{
                            backgroundColor: "#43a047",
                            color: "#f1f8e9",
                            fontWeight: "900",
                          }}
                        />
                      )}
                    </div>
                    <Typography sx={{ fontWeight: "light" }}>
                      {p.description}
                    </Typography>
                  </div>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={
                      p.isEnabled && <PlusIcon sx={{ color: amber[200] }} />
                    }
                    disabled={!p.isEnabled}
                    sx={{
                      minWidth: "120px",
                      fontWeight: 600,
                      flexShrink: 0,
                      color: grey[900],
                    }}
                    onClick={() => {
                      if (p.isEnabled) {
                        setSelectedProduct(p);
                        setExtrasDialogOpen(true);
                      }
                    }}>
                    {p.isEnabled
                      ? `${(p.price / 100).toFixed(2).replace(".", ",")} €`
                      : "SOLD OUT"}
                  </Button>
                </div>
              ))}
          </Element>
        ))}
      </Box>
      <CartFooter openCart={setCartDialogOpen} isOpen={isOpen} />
    </Box>
  );
};

CatalogScreen.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default CatalogScreen;
