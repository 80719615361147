import React from "react"
import { Link } from "react-router-dom"
import Logo from "../assets/images/logo.png"

const Header = () => {
  return (
    <div
      style={{
        padding: 16,
        marginLeft: 16,
        marginRight: 16,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "1px #ddd solid",
      }}
    >
      <Link to="/">
        <img src={Logo} height={120} alt="Frietekes logo" />
      </Link>
    </div>
  )
}

export default Header
