import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import GoogleMap from "../components/GoogleMap";
import cover from "../assets/images/cover.jpg";
import ZoneDialog from "../components/ZoneDialog";
import { useDispatch } from "react-redux";
import { setDelivery } from "../store/session";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../utils/firebase";

const HomeScreen = () => {
  const [isZoneOpen, setIsZoneOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);

  const handleClick = (isDelivery) => {
    if (isDelivery) {
      dispatch(setDelivery(true));
      setIsZoneOpen(true);
    } else {
      dispatch(setDelivery(false));
      navigate("/catalog");
    }
  };

  useEffect(() => {
    (async () => {
      const q = collection(db, "messages");
      const qs = await getDocs(q);

      setMessages(qs.docs.map((ds) => ({ id: ds.id, ...ds.data() })));
    })();
  }, []);

  return (
    <>
      <ZoneDialog open={isZoneOpen} setOpen={setIsZoneOpen} />
      <div className="relative">
        {messages.map((m) => (
          <Alert
            key={m.id}
            variant="filled"
            elevation={6}
            severity={m.variant}
            className="my-4 justify-center items-center gap-8 absolute top-8 left-4 right-4">
            <p>Beste klanten,</p>
            <p className="font-extrabold">{m.message}</p>
            <p>Team Frietekes</p>
          </Alert>
        ))}
      </div>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          gap: 4,
          justifyContent: "space-between",
        }}>
        <div
          style={{
            width: "100%",
            minHeight: 400,
            backgroundImage: `url(${cover})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "cover",
          }}
        />

        <Typography
          sx={{
            m: 2,
            fontWeight: "light",
          }}
          align="center"
          variant="h4">
          Welkom bij <span style={{ fontWeight: "700" }}>FRIET</span>
          <span style={{ fontWeight: "200" }}>EKES</span>
        </Typography>
        <Typography align="center">
          Bij ons kan je terecht om uw frietekes en snacks af te halen (na
          bestelling op de website) of te laten leveren bij u thuis.
          <br /> Hieronder kan je onze leverzones en de minimum bestelbedragen
          vinden:
        </Typography>

        {/* <Box
          sx={{
            p: 5,
            border: 2,
            m: 4,
            display: "flex",
            flexDirection: "column",
            gap: 6,
          }}>
          <Typography align="center" fontWeight={700} fontSize={24}>
            Verzilver hier jouw code* om kans te maken op de reisvoucher van
            1.000€.
          </Typography>
          <Button
            size="large"
            sx={{ mx: "auto" }}
            variant="contained"
            onClick={() => {
              navigate("/tombola");
            }}>
            Verzilver code
          </Button>
          <Typography>
            * De code kan je terugvinden op uw kassaticket die je gekregen hebt
            bij uw bestelling van FRIETekes. Wedstrijd loopt van 1 tot en met 3
            december.
          </Typography>
        </Box> */}

        <Box sx={{ p: 5, display: "flex", flexDirection: "column", gap: 4 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Gijzegem, Hofstade
                </TableCell>
                <TableCell align="right">v.a. 15,00&euro;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Aalst, Herdersem, Nieuwerkerken
                </TableCell>
                <TableCell align="right">v.a. 20,00&euro;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Erembodegem, Haaltert, Lebbeke, Lede, Moorsel, Wieze
                </TableCell>
                <TableCell align="right">v.a. 25,00&euro;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Baardegem, Erpe Mere
                </TableCell>
                <TableCell align="right">v.a. 30,00&euro;</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <ButtonGroup
            disableElevation
            variant="contained"
            size="large"
            color="primary"
            sx={{
              alignSelf: "center",
            }}>
            <Button onClick={() => handleClick(false)}>Afhalen</Button>
            <Button onClick={() => handleClick(true)}>Leveren</Button>
          </ButtonGroup>
        </Box>

        {/* Google Maps map */}
        <GoogleMap />
      </Box>
    </>
  );
};

export default HomeScreen;
