import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../utils/firebase";

export const fetchZones = createAsyncThunk("zones/fetchZones", async () => {
  const q = query(
    collection(db, "zones"),
    where("isEnabled", "==", true),
    orderBy("minAmount"),
    orderBy("name")
  );
  const qs = await getDocs(q);

  return qs.docs.map((ds) => ({ ...ds.data(), id: ds.id }));
});

const initialState = {
  isLoading: false,
  data: [],
};

const zoneSlice = createSlice({
  name: "zones",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchZones.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchZones.pending, (state) => {
        state.isLoading = true;
        state.data = [];
      })
      .addCase(fetchZones.rejected, (state) => {
        state.isLoading = false;
        state.data = [];
      });
  },
});

export const { actions, reducer } = zoneSlice;
