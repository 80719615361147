import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { loadState, saveState } from "./localStorage"
import debounce from "debounce"
import cartReducer from "./cart"
import { reducer as sessionReducer } from "./session"
import { reducer as zonesReducer } from "./zones"
import { reducer as couponReducer } from "./coupon"

const rootReducer = combineReducers({
  cart: cartReducer,
  session: sessionReducer,
  zones: zonesReducer,
  coupon: couponReducer,
})

export const store = configureStore({
  devTools: true,
  reducer: rootReducer,
  preloadedState: loadState(),
})

store.subscribe(
  debounce(() =>
    saveState({
      cart: store.getState().cart,
      session: store.getState().session,
    })
  ),
  5000
)
