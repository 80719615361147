import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
  CircularProgress,
  Box,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import QtyStepper from "./cart/QtyStepper";

import { addProduct } from "../store/cart";
import { db } from "../utils/firebase";
import DialogHeader from "./DialogHeader";
import { selectListStyle } from "../utils/selectListStyle";
import { useSnackbar } from "notistack";

const ExtrasDialog = ({ product, open, setOpen }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  // Local states
  const [qty, setQty] = useState(1);
  const [extras, setExtras] = useState([]);
  const [isExtrasLoading, setIsExtrasLoading] = useState(false);
  const [selectedExtras, setSelectedExtras] = useState(
    new Map(product?.extraGroups?.map((eg) => [eg.groupId, { extras: [] }]))
  );
  const [selectedSize, setSelectedSize] = useState(
    product?.sizes?.length ? product?.sizes[0] : null
  );
  // Calculate temporary price - not in an useEffect
  const extrasPrice =
    Array.from(selectedExtras.values())
      .filter((se) => !se.included)
      .map((se) => se.extras)
      .flat()
      .reduce((prev, curr) => prev + curr.price, 0) ?? 0;
  const tempPrice =
    (product?.price ?? 0) +
    extrasPrice +
    (selectedSize ? selectedSize.price : 0);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let ignore = false;
    if (product) {
      if (product.extraGroups) {
        setIsExtrasLoading(true);
        const getExtras = async () => {
          const extraGroups = product.extraGroups;
          const q = query(
            collection(db, "products"),
            where(
              "relatedTags",
              "array-contains-any",
              extraGroups.map((ex) => ex.id)
            ),
            where("isEnabled", "==", true),
            orderBy("order")
          );

          const results = await getDocs(q);

          const xtras = results.docs.map((ds) => ({
            ...ds.data(),
            id: ds.id,
          }));

          const cats = extraGroups.map((ex) => {
            return {
              ...ex,
              extras: xtras.filter((e) => e.relatedTags.includes(ex.id)),
            };
          });

          const defaultSelectedExtras = new Map(
            cats.map((eg) => [
              eg.groupId,
              {
                included: eg?.included,
                extras: eg?.required ? eg.extras[0] : [],
              },
            ])
          );

          setSelectedExtras(defaultSelectedExtras);
          setExtras(cats);
          setIsExtrasLoading(false);
        };

        if (!ignore) {
          getExtras();
        }
      }
    }

    return () => {
      ignore = true;
    };
  }, [product]);

  const handleAddToCart = () => {
    dispatch(
      addProduct({
        name: product?.name,
        size: selectedSize ? selectedSize?.ticketLabel : null,
        price: tempPrice,
        extras: Array.from(selectedExtras.values())
          .map((e) => e.extras)
          .flat()
          .map((e) => {
            return {
              id: e.id,
              name: e.name,
              order: e.order,
              price: e.price ?? 0,
            };
          }),
        qty: qty,
        categoryOrder: product?.categoryOrder,
        order: product?.order,
        vat: product?.vat,
      })
    );
    // Display notification
    enqueueSnackbar(`${product?.name} is succesvol toegevoegd!`, {
      autoHideDuration: 3000,
      variant: "success",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{ userSelect: "none" }}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <span>{product?.name}</span>
        <span>
          {((tempPrice * qty) / 100).toFixed(2).replace(".", ",")} &euro;
        </span>
      </DialogTitle>
      <DialogContent sx={{ minHeight: "500px" }}>
        {product?.description && (
          <Typography variant="subtitle2" sx={{ fontWeight: "light" }}>
            {product.description}
          </Typography>
        )}
        <QtyStepper qty={qty} setQty={setQty} />

        {product?.sizes?.length && (
          <>
            <DialogHeader label="Grootte" />
            <Select
              isSearchable={false}
              options={product.sizes.filter((o) => o.isEnabled)}
              styles={selectListStyle}
              value={selectedSize}
              onChange={(option) => setSelectedSize(option)}
              formatOptionLabel={(option) => (
                <div className="d-flex justify-content-between">
                  <span className="fw-bold">{option.label}</span>
                  {option.price > 0 && (
                    <span className="fw-light">
                      + {(option.price / 100).toFixed(2).replace(".", ",")}{" "}
                      &euro;
                    </span>
                  )}
                </div>
              )}
            />
          </>
        )}

        {product?.extraGroups && <DialogHeader label="Extras" />}
        {isExtrasLoading ? (
          <CircularProgress size={40} disableShrink />
        ) : (
          extras.map((eg) => (
            <FormControl
              key={eg.groupId}
              component="form"
              fullWidth
              sx={{ my: 1 }}>
              <Typography
                component="legend"
                variant="overline"
                sx={{ fontWeight: "medium", color: "primary.main" }}>
                {eg.label}
              </Typography>
              {eg?.max && (
                <FormHelperText>
                  <Typography variant="caption">
                    Maximaal {eg?.max} keuze(s)
                  </Typography>
                </FormHelperText>
              )}
              {eg?.required && (
                <FormHelperText>
                  <Typography variant="caption">
                    Verplicht minstens 1 keuze te maken
                  </Typography>
                </FormHelperText>
              )}
              <Select
                styles={selectListStyle}
                isMulti={eg?.isMultiple}
                placeholder={eg?.placeholder}
                options={eg?.extras}
                getOptionValue={(o) => o.id}
                getOptionLabel={(o) => o.name}
                isClearable={!eg?.required}
                isSearchable={false}
                // isSearchable={eg?.isMultiple}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                noOptionsMessage={() => "Geen extras gevonden"}
                formatOptionLabel={(o, settings) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {o.name}
                    </Typography>
                    {settings.context === "menu" &&
                      !eg?.included &&
                      o.price > -10 && (
                        <Typography variant="body1" sx={{ fontWeight: 300 }}>
                          + {(o.price / 100).toFixed(2).replace(".", ",")}{" "}
                          &euro;
                        </Typography>
                      )}
                  </Box>
                )}
                value={selectedExtras.get(eg.groupId)?.extras ?? []}
                isOptionDisabled={() =>
                  eg?.max &&
                  eg?.max <= selectedExtras.get(eg.groupId).extras.length
                }
                onChange={(option) => {
                  setSelectedExtras(
                    (prevExtras) =>
                      new Map(
                        prevExtras.set(eg.groupId, {
                          ...prevExtras.get(eg.groupId),
                          included: eg?.included,
                          extras: option
                            ? eg?.isMultiple
                              ? option
                              : [option]
                            : [],
                        })
                      )
                  );
                }}
              />
            </FormControl>
          ))
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 5 }}>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          onClick={handleClose}>
          Annuleer
        </Button>
        <Button
          disabled={isExtrasLoading}
          size="large"
          variant="contained"
          color="primary"
          sx={{ color: "white" }}
          onClick={handleAddToCart}>
          Voeg toe
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExtrasDialog.propTypes = {
  product: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ExtrasDialog;
