import React from "react";
import ReactDOM from "react-dom/client";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { theme } from "./utils/muiTheme";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

import "./index.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { OpenContextProvider } from "./contexts/IsOpenContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CssBaseline>
    {/* Use a custom theme in MUI */}
    <ThemeProvider theme={theme}>
      {/* BrowserRouter wrapper */}
      <Provider store={store}>
        <BrowserRouter>
          <SnackbarProvider>
            <OpenContextProvider>
              <App />
            </OpenContextProvider>
          </SnackbarProvider>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </CssBaseline>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
